import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 47 44"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#fff",
      stroke: "currentColor",
      "stroke-width": "1.5",
      d: "M11.087 20.375a10.9 10.9 0 0 1-7.474-2.908 9.7 9.7 0 0 1-2.237-3.158 9.5 9.5 0 0 1-.815-3.927 9.4 9.4 0 0 1 .815-3.865 10 10 0 0 1 2.237-3.198 11 11 0 0 1 3.345-2.181 10.5 10.5 0 0 1 4.129-.813c1.397-.008 2.781.27 4.068.813a11.4 11.4 0 0 1 3.374 2.181 9.8 9.8 0 0 1 2.266 3.198 9.4 9.4 0 0 1 .815 3.865 9.57 9.57 0 0 1-3.081 7.106 11.2 11.2 0 0 1-3.374 2.12 10.6 10.6 0 0 1-4.068.767ZM33.529 2.93q1.396-1.92 3.897-1.919h1.453q2.036 0 2.618 1.135.582 1.134-.7 2.76l-27.631 35.76q-1.384 1.922-3.877 1.922H7.834q-2.037 0-2.616-1.135-.579-1.134.698-2.76zM11.087 13.396c.84.02 1.655-.294 2.266-.872a2.9 2.9 0 0 0 .931-2.21 2.85 2.85 0 0 0-.931-2.121 3.297 3.297 0 0 0-4.535 0 2.86 2.86 0 0 0-.931 2.121 2.9 2.9 0 0 0 .931 2.21 3.18 3.18 0 0 0 2.269.872ZM25.39 33.282a9.4 9.4 0 0 1 .813-3.868 10.1 10.1 0 0 1 2.24-3.197 10.9 10.9 0 0 1 3.344-2.18 10.5 10.5 0 0 1 4.126-.815 10.3 10.3 0 0 1 4.071.816 11.4 11.4 0 0 1 3.374 2.179 9.8 9.8 0 0 1 2.266 3.197 9.4 9.4 0 0 1 .816 3.868 9.57 9.57 0 0 1-3.082 7.093 11.2 11.2 0 0 1-3.374 2.123 10.6 10.6 0 0 1-4.07.79 10.86 10.86 0 0 1-7.472-2.908 9.7 9.7 0 0 1-2.24-3.158 9.6 9.6 0 0 1-.813-3.94Zm7.326-.057a2.9 2.9 0 0 0 .931 2.207 3.38 3.38 0 0 0 4.535 0 2.9 2.9 0 0 0 .931-2.208 2.87 2.87 0 0 0-.931-2.123 3.305 3.305 0 0 0-4.535 0 2.86 2.86 0 0 0-.936 2.123h.005Z"
    }, null, -1)
  ])))
}
export default { render: render }